// import { AnimatePresence } from 'framer-motion'

const React = require("react");
// const Layout = require("./src/components/layout").default;

const { AnimatePresence } = require('framer-motion')
const { motion } = require('framer-motion')

const { registerLinkResolver } = require('@prismicio/gatsby-source-prismic-graphql');
const { linkResolver } = require('./src/utils/linkResolver');

registerLinkResolver(linkResolver);

exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // const { pathname } = location
  // list of routes for the scroll-to-top-hook
  // const scrollToTopRoutes = [`/privacy-policy`]
  // // if the new route is part of the list above, scroll to top (0, 0)
  // if (scrollToTopRoutes.indexOf(pathname) !== -1) {
    window.scrollTo(0, 0)
  // }

  return false
}

exports.wrapPageElement = ({ element, props }) => (
  <AnimatePresence exitBeforeEnter>
    <motion.div
      initial={{ opacity: 0, x: 0 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 0 }}
      transition={{
        type: "spring",
        mass: 0.35,
        stiffness: 75,
        duration: 1
      }}
    >
    {element}
    </motion.div>
  </AnimatePresence>
);


// export const wrapPageElement = ({element}) => (
//   <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
// );


// https://stackoverflow.com/questions/58225352/need-to-refresh-previously-vistied-gatsby-site-to-view-changes
// trigger an immediate page refresh when an update is found 
// export const onServiceWorkerUpdateReady = () => window.location.reload();
exports.onServiceWorkerUpdateReady = () => window.location.reload();