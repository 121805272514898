export const linkResolver = (doc, langOverride) => {
  const docTypename = doc?.__typename
  const docType = doc?.type ?? doc?._meta?.type ?? doc
  const docUid = doc?._meta?.uid ?? doc?.uid
  const docLang = langOverride ?? doc?._meta?.lang ?? doc?.lang 
  // console.log('[linkResolver.js] doc', doc)
  // console.log('[linkResolver.js] docUid', docUid)
  let url
  switch (docType) {
    case 'home':
      url = `/`
      break
    case 'page':
    case 'archivePage':
    case 'careers':
      url = `/${docUid}`
      break
    case 'product':
      url = `/discover-our-coffees/${docUid}`
      break
    case 'fazenda':
      url = `/our-fazendas/${docUid}`
      break
    case 'legal':
      url = `/legal/${docUid}`
      break
    case 'coffee_family':
      url = `/discover-our-coffees#${docUid}`
      break
    case 'article':
      url = `/our-blog/${docUid}`
      break
    case 'discover-our-coffees':
      url = `/discover-our-coffees`
      break
    
    
    default:
      url = `/${docUid}`
  }

  if (docLang !== process.env.GATSBY_DEFAULT_LANGUAGE) {
    url = `/${docLang}${url}`
  }

  if(docTypename === "PRISMIC__ExternalLink") {
    return doc?.url
  }

  return url
};